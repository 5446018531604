import { Observable } from 'rxjs';
import { ListVariableQuery, ListVariableResponse } from './list-variable.dto';
import {
  WriteVariablePayload,
  WriteVariableResponse,
} from './write-variable.dto';

export abstract class VariableRepository {
  abstract list(query?: ListVariableQuery): Observable<ListVariableResponse>;

  abstract create(payload: WriteVariablePayload): Observable<WriteVariableResponse>;

  abstract update(payload: WriteVariablePayload): Observable<WriteVariableResponse>;

  abstract delete(payload: number): Observable<any>;
}
